import MDRouter from "@/service/router";
import { env, domPixelRatio } from "md-base-tools/env";
import domain from '@/service/domain';
import { mapState } from "vuex";
import { getUserCollect, getCardLevel } from "@/api/index";
import CompCardImg from '@@/luckycards/cardImg.vue';
import { GoBack } from "@/utils/tools";
export default {
  components: {
    CompCardImg
  },
  computed: {
    ...mapState({
      userId: state => state.user.userId
    }),
    card_list_url() {
      let urldata = this.card_list.map(e => {
        if (e.have_back_flag) {
          e.showBack = false;
        }
        return e;
      });
      return urldata;
    }
  },
  data() {
    return {
      domPixelRatio: Math.floor(domPixelRatio()),
      show: false,
      imgIndex: 0,
      id: '',
      env,
      card_name: '',
      name: '',
      list_main_image_url: '',
      // 项目图
      collect_tab_index: "0",
      collect_tab_list: [{
        name: '全部',
        type: "0"
      }, {
        name: '已收集',
        type: "1"
      }, {
        name: '待收集',
        type: "2"
      }],
      level_tab_index: null,
      level_tab_list: [],
      card_list: [],
      card_number: 0,
      have_card_number: 0
    };
  },
  created() {
    if (env.isWxApp()) {
      let query = {
        ...this.$route.query
      };
      const path = this.$route.path;
      let t = new Date().getTime();
      query.t = t;
      query.darkmode = true;
      this.$router.replace({
        path,
        query
      });
    }
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getLevel();
    }
  },
  async mounted() {
    // if (!this.userId) {
    //     return redirect.to('login')
    // }
    if (this.$route.query.id) {
      await this.getCollectDetail();
    }
    if (env.isWxApp()) {
      await this.$nextTick();
      // eslint-disable-next-line no-undef
      postWXMessage({
        title: this.name,
        imageUrl: this.list_main_image_url,
        url: `${domain.wap}/luckycards/fastsell/detail/${this.id}`
      });
    }
  },
  methods: {
    jumpCard() {
      const url = `${domain.wap}/luckycards/container`;
      MDRouter.Link(url);
    },
    showImgPreview(index) {
      this.show = true;
      this.imgIndex = index;
    },
    getLevel() {
      getCardLevel({
        id: this.id
      }).then(res => {
        if (res.status == 0) {
          this.level_tab_list = res.data;
          console.log(this.level_tab_list);
        } else {
          this.level_tab_list = [];
        }
      });
    },
    async getCollectDetail() {
      const {
        status,
        data
      } = await getUserCollect({
        user_id: this.userId,
        id: this.id,
        have_collect: this.collect_tab_index || '',
        level_id: this.level_tab_index || '',
        card_name: this.card_name
      });
      if (status == 0) {
        this.name = data.name;
        this.card_number = data.card_number;
        this.have_card_number = data.have_card_number;
        this.list_main_image_url = data.list_main_image_url;
        this.card_list = data.list;
      } else {
        this.card_list = [];
      }
    },
    collectTabChange(type) {
      this.collect_tab_index = type;
      this.getCollectDetail();
    },
    levelTabChange(type) {
      if (type == this.level_tab_index) {
        this.level_tab_index = "";
      } else {
        this.level_tab_index = type;
      }
      this.getCollectDetail();
    },
    goBack() {
      GoBack();
    }
  }
};